var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: { columns: _vm.columns, "data-source": _vm.invoices },
    scopedSlots: _vm._u([
      {
        key: "member",
        fn: function (text, row) {
          return _c(
            "div",
            {},
            [
              row && row.first_name
                ? [
                    _vm._v(
                      "\n      " +
                        _vm._s(row.first_name) +
                        " " +
                        _vm._s(row.last_name) +
                        "\n    "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        },
      },
      {
        key: "total",
        fn: function (text) {
          return _c("div", {}, [_vm._v("€" + _vm._s(_vm.formatMoney(text)))])
        },
      },
      {
        key: "transaction",
        fn: function (text, row) {
          return _c(
            "div",
            {},
            [
              row.status === "complete"
                ? [
                    _c("a-tag", { attrs: { color: "green" } }, [
                      _vm._v("Payment Received"),
                    ]),
                  ]
                : _vm._e(),
              row.status === "refund"
                ? [
                    _c("a-tag", { attrs: { color: "orange" } }, [
                      _vm._v("Payment Refund"),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }