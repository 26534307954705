var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("n-page", [
    _c("div", { staticClass: "gx-profile-banner gx-blue-cyan-gradient" }, [
      _c("div", { staticClass: "gx-profile-container" }, [
        _c("div", { staticClass: "gx-profile-banner-top" }, [
          _c("div", { staticClass: "gx-profile-banner-top-left" }, [
            _c("div", { staticClass: "gx-profile-banner-avatar-info" }, [
              _c(
                "h2",
                {
                  staticClass:
                    "gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light",
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.session.team && _vm.session.team.team_name) +
                      "\n            "
                  ),
                ]
              ),
              _c("p", { staticClass: "gx-mb-0 gx-fs-lg" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.session.location) +
                    " @ " +
                    _vm._s(_vm.nTime(_vm.session.start)) +
                    "\n            "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm.session.id
      ? _c(
          "div",
          { staticClass: "flex justify-center" },
          [
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c(
                  "a-col",
                  { attrs: { lg: 12, xs: 24 } },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass:
                          "\n            gx-card-widget\n            gx-card-full\n            gx-card-tabs\n            gx-card-tabs-right\n            gx-card-profile\n            ant-card-bordered\n          ",
                        attrs: { title: "Member Attendance" },
                      },
                      [
                        _c("session-member-attendance-table", {
                          attrs: {
                            "team-id": _vm.session.team_id,
                            "session-id": _vm.session.id,
                            "session-start": _vm.session.start,
                            "session-data": _vm.session,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { lg: 12, xs: 24 } },
                  [
                    _c(
                      "a-row",
                      { attrs: { gutters: 12 } },
                      [
                        _c(
                          "a-col",
                          { attrs: { lg: 8, xs: 24 } },
                          [
                            _c(
                              "a-card",
                              {
                                staticClass:
                                  "\n                gx-card-widget\n                gx-card-widget\n                gx-card-full\n                gx-p-3\n                gx-bg-cyan\n                gx-text-white\n                ant-card-bordered\n              ",
                              },
                              [
                                _c("div", {}, [
                                  _c(
                                    "div",
                                    { staticClass: "gx-mr-2 gx-mr-xxl-3" },
                                    [
                                      _c("a-icon", {
                                        staticClass: "gx-fs-icon-lg",
                                        attrs: { type: "euro" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "gx-media-body" }, [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "\n                      gx-fs-xxl gx-font-weight-semi-bold gx-mb-1 gx-text-white\n                    ",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    €" +
                                            _vm._s(
                                              parseFloat(
                                                _vm.getTotalInvoiceValue
                                              ).toFixed(2)
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "gx-mb-0 gx-fs-sm" },
                                      [_vm._v("Est. Revenue")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { lg: 8, xs: 24 } },
                          [
                            _c(
                              "a-card",
                              {
                                staticClass:
                                  "\n                gx-card-widget\n                gx-card-widget\n                gx-card-full\n                gx-p-3\n                gx-bg-teal\n                gx-text-white\n                ant-card-bordered\n              ",
                              },
                              [
                                _c("div", {}, [
                                  _c(
                                    "div",
                                    { staticClass: "gx-mr-2 gx-mr-xxl-3" },
                                    [
                                      _c("a-icon", {
                                        staticClass: "gx-fs-icon-lg",
                                        attrs: { type: "like" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "gx-media-body" }, [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "\n                      gx-fs-xxl gx-font-weight-semi-bold gx-mb-1 gx-text-white\n                    ",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.getMembersAttending) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "gx-mb-0 gx-fs-sm" },
                                      [_vm._v("Members Attending")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { lg: 8, xs: 24 } },
                          [
                            _c(
                              "a-card",
                              {
                                staticClass:
                                  "\n                gx-card-widget\n                gx-card-widget\n                gx-card-full\n                gx-p-3\n                gx-bg-orange\n                gx-text-white\n                ant-card-bordered\n              ",
                              },
                              [
                                _c("div", {}, [
                                  _c(
                                    "div",
                                    { staticClass: "gx-mr-2 gx-mr-xxl-3" },
                                    [
                                      _c("a-icon", {
                                        staticClass: "gx-fs-icon-lg",
                                        attrs: { type: "dislike" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "gx-media-body" }, [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "\n                      gx-fs-xxl gx-font-weight-semi-bold gx-mb-1 gx-text-white\n                    ",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.getMembersNotAttending) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "gx-mb-0 gx-fs-sm" },
                                      [_vm._v("Not attending")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { xl: 24, lg: 24, md: 24, xs: 24, sm: 24 } },
                          [
                            _c(
                              "a-card",
                              {
                                staticClass: "gx-card-widget",
                                attrs: { title: "Billing" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gx-table-responsive" },
                                  [
                                    _c("club-session-invoice-table", {
                                      attrs: { "session-id": _vm.session.id },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }